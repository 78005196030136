import axios from 'axios';
import { handleErrorFromRequest } from '@services/handleErrorService.js';
axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

export async function create_or_update_patient({
  patient,
  sendingOption,
  sendingMoment,
  sendingDate,
  redirectionType,
  surveyId,
  sendEsatisEmailing,
}) {
  try {
    const patientData = {
      prefix: patient.prefix === '' ? null : patient.prefix,
      first_name: patient.firstName === '' ? null : patient.firstName,
      last_name: patient.lastName === '' ? null : patient.lastName,
      email: patient.email === '' ? null : patient.email,
      reminder_type: sendingMoment === 'later' ? 'time' : 'now',
      reminder_time: sendingMoment === 'later' ? sendingDate.toString() : null,
      review_type: redirectionType,
      phone_number: patient.phoneNumber === '' ? null : patient.phoneNumber,
      communication_channel: sendingOption,
      survey_id: redirectionType === 'survey' ? surveyId : null,
      tag_id: patient.tagId === '' ? null : patient.tagId,
      esatis_exit_date_column: sendingOption === 'email' && sendEsatisEmailing === 'true' && patient.exitDate !== '' ? patient.exitDate : null,
    };
    const response = await axios.post(`/patients`, { patient: patientData }, { headers: { Accept: 'application/json' } });
    return response;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}

export async function upload_file_and_create_patients({
  file,
  sendingOption,
  sendingMoment,
  sendingDate,
  redirectionType,
  surveyId,
  sendEsatisEmailing,
  establishments,
  importType,
}) {
  try {
    const formData = new FormData();
    if (importType === 'file_one_establishment') {
      formData.append('csv[clinic_id]', establishments[0]);
    } else {
      formData.append('clinic_ids', establishments);
    }
    formData.append('csv[data]', file.selectedFile);
    formData.append('csv[communication_channel]', sendingOption);
    formData.append('csv[document_type]', 'patient');
    if (sendingMoment === 'later') {
      formData.append('csv[reminder_time]', sendingDate);
    }
    formData.append('csv[reminder_type]', sendingMoment === 'later' ? 'time' : 'now');
    formData.append('csv[review_type]', redirectionType);
    if (redirectionType === 'survey') {
      formData.append('csv[survey_id]', surveyId);
    }
    if (file.useTagColumn === true) {
      formData.append('csv[tag_column]', file.tagColumn);
    }
    if (sendingOption === 'email' && sendEsatisEmailing === 'true') {
      formData.append('csv[esatis_exit_date_column]', file.exitDateColumn);
    }
    let response = undefined;
    if (importType === 'file_one_establishment') {
      response = await axios.post(`/csvs`, formData, { headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' } });
    } else {
      response = await axios.post(`/csvs/import_multiclinics_patients`, formData, {
        headers: { 'Content-Type': 'multipart/form-data', Accept: 'application/json' },
      });
    }
    return response;
  } catch (error) {
    handleErrorFromRequest(error);
  }
}
